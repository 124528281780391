import { pinyin } from "pinyin-pro";
import region from '@/assets/region.json'

export default function (lang) {
  const countries = region
  function py(chat) {
    return pinyin(chat, { toneType: 'none' })
  }
  const sortedCountries = countries.sort((a, b) => {
    const nameA = (lang === 'zh' ? py(a.name_sc) : a.name_en).toLowerCase()
    const nameB = (lang === 'zh' ? py(b.name_sc) : b.name_en).toLowerCase()
    return nameA.localeCompare(nameB)
  })
  const groupedCountries = sortedCountries.reduce((groups, country) => {
    const firstLetter = (lang === 'zh' ? py(country.name_sc) : country.name_en).charAt(0).toUpperCase()
    if (!groups[firstLetter])
      groups[firstLetter] = []
    groups[firstLetter].push(country)
    return groups
  }, {})
  const sortedGroupedCountries = Object.entries(groupedCountries)
    .sort()
    .reduce((obj, [letter, countries]) => {
      countries.unshift({
        tag: letter,
      })
      obj[letter] = countries
      return obj
    }, {})

  const flatten = (arr) => {
    return arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val), [])
  }
  const other = Object.values(sortedGroupedCountries)
  const cydq = [
    { tag: lang=='en'?'Commonly used area':'常用地区' },
    { no: '86', code: 'CN', name_sc: '中国', name_tc: '中國', name_en: 'China' },
    { no: '852', code: 'HK', name_sc: '中国香港', name_tc: '中國香港', name_en: 'Hong Kong' },
    { no: '853', code: 'MO', name_sc:'中国澳门',name_tc:'中國澳門', name_en: 'Macau' },
    { no: '886', code: 'TW', name_sc: '中国台湾',name_tc:'中國臺灣', name_en: 'Taiwan' },
  ]
  const result = cydq.concat(other)
  return flatten(result)
}
