<script setup>
import useRegion from '@/assets/hooks/useRegion'
import { useI18n } from 'vue-i18n'
const { locale } = useI18n()
const props = defineProps(['type'])
const emit = defineEmits(['change'])
const region = useRegion(locale.value)
function searchFilter(arr, searchTerm) {
  if (!searchTerm)
    return
    // eslint-disable-next-line array-callback-return
  return arr.filter((obj) => {
    if (!obj.tag) {
      return Object.values(obj).some((value) => {
        return value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      })
    }
  })
}
function handleChage(item) {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  getcode.value = item.no
  emit('change', item)
}
const list = ref(region); const searchVal = ref(''); const getcode = ref('65')
if (process.client) {
  const debounced = refDebounced(searchVal, 500)
  watch(debounced, () => {
    if (searchVal.value)
      list.value = searchFilter(region, searchVal.value)
    else
      list.value = region
  })
}
</script>

<template>
  <div>
    <div absolute h-300px bg-white bg-filter bds-silvergray p-16 pb-0 rounded-8 f-col z-100 w-287px>
        <!-- <div relative>
          <div abs_t i-carbon-search w-16 h-16x left-8 text-body />
          <input v-model="searchVal" focus:outline-none w-full text-14px text-body  py-5 px-25 bg-accent bg-opacity-12 rounded-35 border-none placeholder-body placeholder-opacity-40 py-12 px-40 text-16 p-0 type="text">
          <div
            v-if="searchVal" abs_t i-carbon-close-outline w-20 h-20 right-10 text-body   @click="searchVal = ''" />
        </div> -->
        <div v-if="list.length" w-full text-white text-14px flex-1 overflow-y-auto relative cursor-pointer>
          <template v-for="(item, index) in list" :key="index">
            <div v-if="item.tag" f-c text-body h-35 top-0 items-end>
              {{ item.tag }}
            </div>
            <div
              v-else f-c h-48  border-white border-b border-opacity-50 text-body
              @click="handleChage(item)"
            >
              <span mr-10px>{{ locale==='en'?item.name_en:item.name_sc }} ({{ item.code }})</span>
              <span v-if="!props.type">+{{ item.no }}</span>
              <div v-if="getcode === item.no" i-carbon:checkmark text-body text-16 absolute right-0 />
            </div>
          </template>
        </div>
        <div v-else w-h f-c-c>
          <span text-gray text-16>{{ $t('login12') }}</span>
        </div>
      </div>
  </div>
</template>
